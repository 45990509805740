import React from 'react';
import ReactDOM from 'react-dom';
import { Provider} from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
/* import { createLogger } from 'redux-logger'; */  //REMOVE LOGGER IN DEPLOYMENT
/* import { createBrowserHistory } from 'history'; */
import thunkMiddleware from 'redux-thunk';
import registerServiceWorker from './registerServiceWorker';
/* 
import { useRoutes } from "hookrouter"; 
import routes from "./router";
const routeResult = useRoutes(routes);
  return routeResult;
*/

/*
Async.js ==== This allows you to increase performance by getting rid of render-blocking JavaScript.
Request – Simplified HTTP Client ==== With Request, making HTTP calls becomes an easy, secure, and reliable way of accessing external content on the web, in any form imaginable.
Browserify ==== allows us to create the kind of client-side codebase that’s organized, well-structured, and easy to navigate.
Grunt ==== With a task runner, you can easily allow yourself to have less tasks to care of for a particular project, and instead automate the process of doing minifications, compile tasks, testing, code linting, and so forth. 
PM2 ==== PM2 offers both process management for production applications, and a load-balancer to help with any possible performance tweaks.
Socket.IO ==== Socket lets you build truly real-time communication apps that would require real-time streams of content, whether directly from the data that you are working with, or through an API that comes from an external source.
Commander.js ==== Commander.js (based on Ruby) is a great solution for creating command-line interfaces without any fuss.
Underscore ==== 
UglifyJS2 ==== UglifyJS2 trims and minifies your JavaScript files for you automatically.
Cheerio ==== Cheerio is a fast, flexible, and lean implementation of core jQuery designed specifically for the server.
Passport ==== Passport is a unique authentication module for Node.js devs.
Nodemailer ==== a secure framework for doing email management within your Node.js applications.
Hapi ==== Hapi is a server-framework.
GraphicsMagick ==== GraphicsMagick and ImageMagick are two highly acclaimed tools for manipulating visual content on the web.
*/

import App from './containers/App';

import {
    ChangeRoute, //Geral
    ToggleVisibility, //Geral
    ToggleWeekDay, //De horario de funcionamento
    CycleSlideshow, //De Quotes
    Presentation,
    Intro,
    NextEvent,
    Quotes,
    Portfolio,
    Portfolio_Title,
    Horario,
    Nota_Ferias,
    Details,
    Events,
    User,
    Likes,
    ClientList,
    searchEntity, //Para já, não necessário para Claricia
    SideBars, //Para já, não necessário para Claricia
    NavBar //Para já, não necessário para Claricia
} from './reducers'


/* const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    history.replace(path);
} */

/* const logger = createLogger(); */ //REMOVE LOGGER IN DEPLOYMENT
const rootReducers = combineReducers({
    ChangeRoute, //Geral
    ToggleVisibility, //Geral
    ToggleWeekDay, //De horario de funcionamento
    CycleSlideshow, //De Quotes
    Presentation,
    Intro,
    NextEvent,
    Quotes,
    Portfolio,
    Portfolio_Title,
    Horario,
    Nota_Ferias,
    Details,
    Events,
    User,
    Likes,
    ClientList,
    searchEntity, //Para já, não necessário para Claricia
    SideBars, //Para já, não necessário para Claricia
    NavBar, //Para já, não necessário para Claricia
    form: formReducer
});

const store = createStore(rootReducers, applyMiddleware(thunkMiddleware/* ,logger */)); //REMOVE LOGGER IN DEPLOYMENT

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));
registerServiceWorker();
